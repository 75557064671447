<template>
  <div>
    <div v-if="load && list && list.length > 0" class="post-list">
      <safe-area>
        <div class="post-items">
          <a v-for="item in list" :href="`/content/detail?id=${item.id}`" class="post-item">
            <img :src="item.thumbnail" class="post-thumbnail"/>
            <div class="post-title">{{ item.title }}</div>
            <!--          <div class="post-desc">{{ item.description }}</div>-->
            <div class="post-time">{{ item.publishAt }}</div>
          </a>
        </div>
      </safe-area>
    </div>
    <div v-if="load && list.length ==0">
      <safe-area>
        <div class="empty">
          <el-empty description="暂无相关内容"/>
        </div>
      </safe-area>
    </div>
  </div>

</template>

<script>
import SafeArea from "@/layout/safe-area";
import {GetPostListApi} from "@/api/post";

export default {
  name: "post-list",
  components: {SafeArea},
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      load: false,
      list: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      GetPostListApi({categoryId: this.type, page: 1}).then(res => { // todo 数据分页加载
        this.load = true
        this.list = res.result || []
      })
    }
  }
}
</script>

<style scoped>
.post-list {
  background: #f3f4f5;
  padding-bottom: 20px;
}

.post-items {
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

/* todo 瀑布流自动布局*/
.post-item {
  flex: 0 0 calc(20% - 20px);
  margin: 10px;
}

.post-thumbnail {
  display: block;
  margin: 0;
  width: 100%;
}

.post-title {
  color: #333333;
  padding: 15px 0;
  font-size: 0.9rem;
}

.post-time {
  color: #888888;
  font-size: 0.7rem;
}
</style>
