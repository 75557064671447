<template>
  <div v-if="list && list.length >0" class="nav-tab">
    <safe-area>
      <div class="nav-tab-items">
        <a v-for="item in list" :class="item.title === active ? 'nav-tab-item nav-tab-item-active':'nav-tab-item'"
           :href="item.link">
          {{ item.title }}
        </a>
      </div>
    </safe-area>
  </div>
</template>

<script>
import SafeArea from "@/layout/safe-area";

export default {
  name: "nav-tab",
  components: {SafeArea},
  props: {
    list: {
      type: Array,
      default: []
    },
    active: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.nav-tab {
  background: #f3f4f5;
  padding: 20px 0;
}

.nav-tab-items {
  background: #dddddd;
  display: flex;
  padding: 0;
  border-bottom: 1px solid #c9151e;
}

.nav-tab-item {
  border-right: 2px solid #ffffff;
  padding: 10px 50px;
  color: #333333;
  font-size: 0.8rem;
}

.nav-tab-item-active {
  background: #c9151e;
  color: #ffffff;
}

.nav-tab-item:last-child {
  border-right: 2px solid #dddddd;
}
</style>
