import request from "@/utils/request";


export function GetPostListApi(params) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/content/list',
        params,
        {}
    )
}
